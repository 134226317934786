.Footer{
    padding: 58px 110px;
    background: #FFF9F3;

    .coder{
        width: 149px;
            height: 90px;
        
    }

    .logo{

        svg{
            width: 150px;
            height: 150px;
        }
    }

    .linkdin-text{
        padding: 6px 16px;
        width: 279px;
            height: 36px;
            background: linear-gradient(90deg, #FEBF24 0%, #EA4232 100%);
            font-family: "Poppins";
                font-size: 13.88px;
                font-weight: 400;
                line-height: 24px;
                color: white;
                border-radius: 8px;
                margin-top: 34px;
                margin-bottom: 34.5px;
    }

    .normal-text{
        font-family: "Poppins";
            font-size: 13.75px;
            font-weight: 400;
            line-height: 22px;
            color: #EA4232;
    }

    .space{
        margin-bottom: 60px;
    }

    .head{
        font-family: "Poppins";
            font-size: 15.38px;
            font-weight: 600;
            line-height: 20px;
            color: #EA4232;
            margin-bottom: 24px;
    }

    .haed-text{
        cursor: pointer;
        font-family: "Poppins";
            font-size: 14px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: -0.20499999821186066px;
            color: #EA4232;
    }

    .sp{
        margin-bottom: 25.5px;
    }

    .d-space{
        margin-top: 63.5px;
    }

    .icon-div{
        // margin-top: 46.1px;
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .icon-color{
        color: #EA4232;
    }

    .fotter-sov-svg{
            cursor: pointer;

            svg{
                width: 150px;
                height: 150px;
            }
    }



}

.Footer-mobile{
    display: none;
}

@media only screen and (max-width: 480px) and (min-width: 390px){
    .Footer{
        display: none;
    }
    .Footer-mobile{
        display: block;
    background: #FFF9F3;
        padding: 8px 21px 21px 21px;

        .fotter-sov-svg{
            width: 102px;
                height: 44px;
                cursor: pointer;
        }

        .mobile-div{
            display: flex;
            justify-content: space-between;
            gap: 53px;
            margin-bottom: 20px;
        }

                .normal-text {
                    font-family: "Poppins";
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 22px;
                    color: #EA4232;
                }
        
                .space {
                    margin-bottom: 43px;
                }

                                .head {
                                    font-family: "Poppins";
                                    font-size: 12px;
                                    font-weight: 600;
                                    line-height: 20px;
                                    color: #EA4232;
                                    margin-bottom: 8px;
                                }
                
                                .haed-text {
                                    cursor: pointer;
                                    font-family: "Poppins";
                                    font-size: 10px;
                                    font-weight: 400;
                                    line-height: 20px;
                                    letter-spacing: -0.20499999821186066px;
                                    color: #EA4232;
                                }

                                .sp{
                                    margin: 0;
                                }

                                                                .icon-div {
                                                                    // margin-top: 46.1px;
                                                                    display: flex;
                                                                    align-items: center;
                                                                    gap: 16px;
                                                                }
                                
                                                                .icon-color {
                                                                    color: #EA4232;
                                                                }

    }
}