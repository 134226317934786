.aboutus-glory {
  margin-top: 53px;
  padding: 0px 49px;

  .heading-color {
    font-size: 72px !important;
    text-align: center !important;
    margin-bottom: 46px;
  }

  .para {
    font-family: "Poppins";
    font-size: 40px;
    font-weight: 300;
    line-height: 37px;
    text-align: center;
    color: #5B8E3E;
    margin-bottom: 93px;
  }
      
  .year-glory {
    // margin-bottom: 141px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .year {
    height: fit-content;
    padding: 27px;
    border: 2px solid #ea4232;
        display: flex;
            flex-direction: column;
            align-items: center;
  }

  .nmu {
    font-family: "Poppins";
    font-size: 48px;
    font-weight: 700;
    line-height: 44.4px;
    color: #5B8E3E;
  }

  .txt {
    margin-left: 36px;
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 400;
    line-height: 27.9px;
    color: #6b6b6b;
  }

  .txts{
    margin-left: 36px;
    margin-right: 25px;
        font-family: "Poppins";
        font-size: 18px;
        font-weight: 400;
        line-height: 27.9px;
        color: #6b6b6b;
  }

  span {
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 600;
    line-height: 27.9px;
    color: #6b6b6b;
  }

  // .img-capture {
  //   margin-left: 44px;
  //   width: 530px;
  //   height: 351px;
  //   position: relative;
  //   background: #d9d9d9;
  // }

  // .img-captures{
  //       width: 530px;
  //       height: 351px;
  //       position: relative;
  //       background: #d9d9d9;
  // }

  // .thumbsup {
  //   position: absolute;
  //   height: 339px;
  //   bottom: -16px;
  //   left: -40px;
  //   object-fit: contain;
  // }
}

.aboutus-glory-mob{
  display: none;
}

@media only screen and (max-width: 480px) and (min-width: 390px){
  .aboutus-glory{
display: none;
  }
  .aboutus-glory-mob{
    display: block;
    .heading-color{
        font-size: 22px !important;
        font-weight: 500;
        line-height: 23.56px;
        margin-bottom: 21px;
        text-align: center;
    }

    .para{
        font-size: 12px;
        font-weight: 400;
        line-height: 11.1px;
        margin-bottom: 28px;
        text-align: center;
    }

                    .year-glory {
                      // margin-bottom: 141px;
                      margin-bottom: 50px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      flex-direction: column;

                      .main-year{
                        order: 0;
                        display: flex;
                        align-items: center;
                        padding: 0px 23px 0px 12px;
                      }

                      div{
                        order: -1;

                        img{
                          width: 247.74px;
                        }
                      }
                    }
          
                    .year {
                      margin-left: 7px;
                      height: fit-content;
                      padding: 12px;
                      border: 2px solid #ea4232;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    }
          
                    .nmu {
                      font-family: "Poppins";
                      font-size: 22px;
                      font-weight: 700;
                      line-height: 20.35px;
                      color: #5B8E3E;
                    }
          
                    .txt {
                      margin-left: 36px;
                      font-family: "Poppins";
                      font-size: 12px;
                      font-weight: 400;
                                              line-height: 18.6px;
                      color: #6b6b6b;
                    }
          
                    .txts {
                    margin-left: 36px;
                      font-family: "Poppins";
                      font-size: 12px;
                      font-weight: 400;
                      line-height: 18.6px;
                      color: #6b6b6b;
                    }
          
                    span {
                      font-family: "Poppins";
                      font-size: 12px;
                      font-weight: 600;
                        line-height: 18.6px;
                    }
  }
}
