.navbar {

    display: flex;
    font-family: Poppins;
    width: 100%;
    flex-wrap: nowrap !important;
    box-shadow: 0px 4px 40px 0px #0000001A;

    .logo-box {
        width: 20%;
        justify-content: center;
        display: flex;
    }

    .logo {
        width: 60px;
        height: 60px;
        cursor: pointer;
            // object-fit: cover;
    }

    .list {
        margin: 0;
            margin-left: 300px;
        width: 40%;
        display: flex;
        justify-content: space-between;
        list-style: none;
        align-items: center;
        cursor: pointer;

        li {
            font-weight: 500;
            font-family: 'Poppins';
        }

        li:hover {
            color: red;
        }
    }

    .nav-btn-box {
        width: 20%;
        justify-content: center;
        display: flex;
    }

    .nav-btn {
        border-radius: 35px;
        height: 34px;
        background: linear-gradient(to right, #FFCC21 0%, #EA4335 100%);
        font-weight: 400;
        font-size: 14px;
        border: none;
        padding: 5px 12px 5px 12px;
        margin: 10px;
        cursor: pointer;
    }

    .p-dropdown{
        background: white;


    }

    .custom-dropdown{
        background-color: white; 
    }
       

    .dropdown-container {
        position: relative;
      }
      
      .dropdown-toggle {
        background-color: #f8f8f8;
        border: 1px solid #ddd;
        padding: 10px 20px;
        cursor: pointer;
        font-size: 16px;
      }
      
      .dropdown-menu {
        position: absolute;
        top: 100%;
        background-color: #FFF9F3;
        border: 1px solid #ddd;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 60px 100px 0px 100px;
      }
      
      .dropdown-category {
        margin-bottom: 15px;
      }
      
      .category-title {
        font-family: "Poppins";
font-size: 14px;
font-weight: 400;
line-height: 21px;
        padding: 10px 20px;
        border-bottom: 1px solid #ddd;
        width: 25%;
        background:  linear-gradient(to right, #FFCC21 0%, #EA4335 100%);
        color: white;
        height: 2.5rem;
        border-radius: 5px;
        margin-right: 2rem;
      }
      
      .category-items {
        display: flex;
        flex-wrap: wrap;

        span{
          margin-left: 1rem;
        }
      }


      
      .dropdown-item {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        text-decoration: none;
        color: #333;
        flex: 1 1 50%; /* Adjust the flex-basis for responsive layout */
      }

      .text-box{
        width: 60%;
        display: flex;
        justify-content: center;
        margin: auto;
        border: 1px solid #FEBF24 ;
        border-radius: 11px;
        padding: 10px;
        margin-bottom: 1rem;


        span{
          font-family: Inter;
font-size: 13.5px;
font-weight: 600;
line-height: 16.34px;
text-align: left;
color: #EA4335;
margin-left: 1rem;
margin-top: 0.5rem;
        }

      }

      .btn{
height: 28px;
border-radius: 10px;
border: none;
background: linear-gradient(to right, #FFCC21 0%, #EA4335 100%);
color: white;
font-family: "Inter";
font-size: 11.5px;
font-weight: 600;
line-height: 13.92px;
margin-left: 1rem;
      }

      .btn:hover{
        color: white;
      }
      
      .dropdown-item img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
      
      .dropdown-item:hover {
        background-color: #f0f0f0;
      }

      .list-box{
        display: none !important;
    }

    .ham{
      display: none;
    }

}

@media only screen and (max-width: 480px) and (min-width: 300px) { 
  .navbar{
    width: 100%;


    // .logo-box {
    //   width: 100%; 
    // }

    .list2 {
      flex-direction: column;
      margin: 0;
      padding: 0;
      align-items: center;
      height: fit-content;
      text-align: center;


      li{
        margin: 0.5px;
        padding: 10px;
        font-size: 16px !important;
        line-height: 30px;
        font-family: "Montserrat" !important;
        font-weight: 500 !important;
      }
  }

  .dropdown{
    padding: 10px;
    
    .arrow {
      position: absolute;
      right: 40px;
    }

   
  }

  .list{
    display: none;
  }

    .logo-box {
      width: 40% !important; 
    }

    .nav-btn-box{
      display: none !important;
    }

    .ham{
      width: 20%;
      position: absolute;
      right: 10px;
      display: block !important;
      cursor: pointer;
    }

    .dropdown-button{
      background: none;
      border: none;
    }

    .dropdown-content{
      height: 30vh;
      overflow-y: auto;
    }

    .active  {
      display: flex !important; 
      flex-direction: column;
      margin-top: 3.5vh;
  }

  .list-box {
    width: 100%;
    flex-direction: column;
    position: absolute;
    top: 50px; 
    right: 0;
    z-index: 1000;
    background: white;
   
    
}
  }
}