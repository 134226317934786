.Customer {
  padding: 67px 81px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;

  .dash {
    width: 69px;
    height: 5px;
    background: linear-gradient(90deg, #FEBF24 0%, #EA4232 100%);
    margin-bottom: 20px;
  }

  .head1 {
    // font-family: "Inter";
    font-size: 35px;
    font-weight: 400;
    line-height: 55px;
    text-align: center;
    color: #5B8E3E;
  }

  .head2 {
    // font-family: "Inter", sans-serif;
    font-size: 35px;
    font-weight: 700;
    line-height: 55px;
    text-align: center;
    color: #5B8E3E;
  }

  .mySwiper {
    // margin-top: 20px;
    width: 80%;
    height: fit-content;

    .swiper-wrapper {
      .swiper-slide-active {
        // display: flex;
        //     justify-content: center;
        padding-left: 220px;
        padding-right: 220px;
      }

      .testimonial-card {
        text-align: center;

        h1 {
          font-family: "Inter", sans-serif;
          font-size: 25px;
          font-weight: 700;
          line-height: 40px;
          text-align: center;
          color: #5B8E3E;

        }
      }

      .review {
        display: flex;
        font-family: "Inter";
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        text-align: center;
        color: #718096;
        margin-bottom: 2rem;
        margin-top: 4rem;
        height: 15vh;

        p {
          margin-top: 1.5rem;
        }

      }


    }

    .svg1 {
      margin-right: 1rem;
    }

    .svg2 {
      margin-top: 5rem;
      margin-left: 1rem;
    }

    .swiper-button-prev {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      // border: 1px solid #EA4232;
      color: #ea4232;
      color: #ffffff;
      background: linear-gradient(180deg, #febf24 0%, #ea4232 100%);
    }

    .swiper-button-disabled {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      border: 1px solid #ea4232;
      color: #ea4232;
      background: transparent;
    }

    .img-box {
      align-items: center;
      text-align: center;
      display: flex;
      flex-direction: column;
    }

    .swiper-button-next {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      color: #ffffff;
      background: linear-gradient(180deg, #febf24 0%, #ea4232 100%);
    }

    .swiper-button-prev::after {
      font-size: 24px !important;
    }

    .swiper-button-next::after {
      font-size: 24px !important;
    }

    .swiper-pagination {
      display: none;
    }
  }
}

@media only screen and (max-width: 480px) and (min-width: 390px) {
  .Customer {
    padding: 0;

    .head1,
    .head2 {
      font-family: "Poppins";
      font-size: 22px;
      font-weight: 600;
      line-height: 30px;
      text-align: center;
    }

    .mySwiper {
      width: 100%;
    }

    .swiper {
      margin-left: 0;
      margin-right: 0;
    }

    .mySwiper .swiper-wrapper .swiper-slide-active {
      padding-left: 0;
      padding-right: 0;
    }

    .mySwiper .swiper-wrapper .review {
      padding: 20px;
      height: 30vh;
    }
  }
}