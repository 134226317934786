.developer {
  width: 100%;
  padding: 0;
  margin: 0;

  .devbox {
    width: 100%;
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .devleft {
    width: 50%;
    padding-left: 80px;
    margin: auto;
  }

  .devright {
    width: 50%;
    padding: 40px;
    object-fit: contain;
  }

  .input-field {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    width: 313.23px;
    height: 50px;
    padding: 13px 167.23px 13px 16px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    outline: none;
    color: #000000;
    margin-bottom: 1rem;

    ::placeholder {
      color: #6c757d;
    }
  }

  .send-btn {
    border: none;
    width: 172.42px;
    height: 50px;
    padding: 14.5px 17.99px 15.5px 25px;
    border-radius: 0px 4px 4px 0px;
    background: #ea4232;
    font-family: "Poppins";
    font-size: 15.63px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
  }

  h3 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #ea4232;
    cursor: pointer;
  }

  h2 {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: #ea4232;
    margin-bottom: 2rem;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    color: #1d2434b8;
    padding: 0;
    margin: 0;
  }

  .landing-bar {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    background: white;
    border-radius: 35px;
    // filter: drop-shadow(1px 1px 1px);
    border: 1px solid #ee5c31;
    font-size: 16px;
    padding: 20px;
  }

  .head-main {
    font-size: 30px;
    font-weight: 600;
    line-height: 46px;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: #5B8E3E;

    span {
      color: #ea4232;
    }
  }

  .find-box-main {
    width: 100%;
    text-align: center;
    padding: 40px 40px;

    .head-main {
      margin-top: 0;
    }

    h2 {
      color: #5B8E3E;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      margin: 0;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;
      color: #666666;
      margin-bottom: 1rem;
    }
  }

  .find-box {
    width: 100%;
    display: flex;
  }

  .find-box-right {
    width: 50%;
  }


  .find-sm-box {
    border: 1px solid #febf24;
    background: #fff9f3;
    border-radius: 20px;
    margin: 2rem;
    text-align: left;
    padding: 20px;
    padding-bottom: 0;

    img {
      border: 1px solid #febf24;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      background: white;
      border-bottom: none;
    }

    .input-field {
      margin-top: 1rem;
      margin-bottom: 4rem;
    }
  }

  .find-box-left {
    position: relative; 
    width: 50%;
    background: #fff9f3;
    border-radius: 20px;
    margin: 2rem;
    text-align: left;
    padding: 20px;
    padding-bottom: 0;
    border: 1px solid #febf24;
  }
  

  .img-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }

  .comm-box {
    width: 100%;
    background: #fff9f3;
    justify-content: center;
    padding: 50px 50px;
    padding-bottom: 0;


    h1 {
      color: #ea4232;
    }

    h2 {
      color: #5B8E3E;
      margin-bottom: 0;
    }
  }

  .comm-sm-box {
    width: 88%;
    display: flex;
    justify-content: space-between;
    margin: auto;
  }

  .comm {
    width: 20%;
  }

  .btn-box {
    display: flex;
    background: url("../../assests/bg2.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    justify-content: center;
    width: 100%;
    padding: 3rem;
    color: #ea4232;

    span {
      margin-top: 0.4rem;
    }
  }

  .btn1 {
    padding: 6px 15px 7px 16px;

    border-radius: 4px;
    background: #ea4232;
    color: white;
    border: none;
    margin-right: 1rem;
  }

  .btn2 {
    padding: 6px 15px 7px 16px;
    border: none;
    margin-left: 0.2rem;
    color: #ea4232;
    background: none;
  }

  .growth-box-main{
    width: 100%;
    overflow: hidden;
  }

  .growth-box {

    h2 {
      font-size: 18px;
      font-weight: 400;
      line-height: 32px;
      margin: 10px;
      color: #5B8E3E;
      margin-bottom: 0;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #000000b8;
      margin: 10px;
    }
  }

  .growth-sm-box {
    background: #fff9f3;
    margin: 1rem;
    padding: 40px;
    border-radius: 4px;
  }

  .slider-container {
    position: relative;
    width: 80%;
    margin: 0 auto;
  }
  
  .growth-box {
    display: flex;
    transition: transform 0.5s ease;
  }
  
  .growth-sm-box {
    min-width: 30%;
    box-sizing: border-box;
  }
  
  .slider-buttons {
    text-align: center;
    margin-top: 1rem;
    background: none;
  }
  
  .slider-buttons button {
    background : none;

    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    margin-bottom: 5rem;
  }
  
  .slider-buttons button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }


  .client-box-main{
    background: #FFF9F3;
    padding: 40px;
    overflow: hidden;
    width: 100%;
    margin-bottom: 6rem;
    align-items: center;

    h2{
      text-align: center;
      color: #666666;
      margin-bottom: 0;
    }

    .head-main{
      margin-top: 0;
    }
  }

  .client-box{
    width: 100%;
    display: flex;
    justify-content: center;

    img{
      margin: 0.5rem;
      padding: 10px;
    }
  }

  .client-box-sm{

      width: 25%;
      box-sizing: border-box;
      border: 1px solid #febf24;
      padding: 20px 30px;
      border-radius: 30px;
      background: white;
      margin: 15px;
      font-style: italic;

      p{
        margin-top: 0.5rem;
      }

      svg{
        margin-bottom: 0.5rem;
      }
    
  }
  
}

@media only screen and (max-width: 480px) and (min-width: 390px){ 

  .developer{

    .devbox{
      flex-direction: column;
    }

    .devleft{
      width: 100%;
      padding: 30px;
    }

    .devright{
      width: 100%;

      img{
        width: 90%;
      }
    }

    .heading-main{
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 0;
    }

    .heading-color{
      font-size: 22px;
    }

    .find-box{
      flex-direction: column;
    }

    .find-box-left{
      width: 100%;
      margin: 0;
    }

    .find-box-right{
      width: 100%;
    }

    .img-box{

      img{
        width: 100%;
      }
    }

    .find-sm-box{
      margin: 0;
      margin-top: 20px;
    }

    .comm-sm-box{
      flex-direction: column;
    }

    .comm{
      width: 100%;
    }

    .comm-box{
      padding: 10px;
    }

    .head-main{
      font-size: 22px;
      line-height: 30px;
    }

    .growth-sm-box{
      width: 100%;
      min-width: 100%;
    }

    .landing-bar{
      overflow-x: auto;
    }

    .client-box{
      flex-wrap: wrap;
    }

    .client-box-sm{
      width: 100%;
    }
  }
}