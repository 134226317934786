.slider-service {
  padding: 46px 0px 0px 0px;
  background: #fff9f3;

  .service-head {
    display: flex;
    justify-content: center;
    font-family: "Inter";
    font-size: 35px;
    font-weight: 700;
    line-height: 55px;
    color: #5B8E3E;
    margin-bottom: 63px;

  }

  .mySwiper {
    height: 367px;
    padding: 0px 15px 0px 15px;

    .swiper-wrapper {
      display: flex;

      .swiper-slide {
        cursor: pointer;
        width: 333px;
        height: 287px;
        border-radius: 7px;
        border: 1px solid #ea4232;
        padding: 38.5px 16px;
        transition: transform 0.3s ease;
        background: white;
      }

      .swiper-slide:hover {
        transform: translateY(39px);
        box-shadow: 0px 4px 30px 0px #0000001A;
      }

      .img-div {
        width: 58px;
        height: 58px;
        padding: 12px;
        border: 1px solid #ea4232;
        border-radius: 50%;
        margin-bottom: 15px;
      }

      .ServiceIcon1 {
        width: 30px;
        height: 30px;
      }

      .title {
        font-family: "Inter";
        font-size: 20px;
        font-weight: 600;
        background: linear-gradient(90deg, #febf24 0%, #ea4232 100%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
        margin-bottom: 20px;
      }

      .des {
        font-family: "Inter";
        font-size: 14px;
        font-weight: 400;
        line-height: 22.68px;
        color: #4a5568;
      }
    }

    .swiper-button-prev {
      display: none;
    }

    .swiper-button-next {
      display: none;
    }

    .swiper-pagination-bullet-active {
      width: 18px;
      height: 18px;
      background: linear-gradient(225deg, #FEBF24 0%, #EA4232 100%);
      border: none !important;
    }

    .swiper-pagination-bullet {
      width: 18px;
      height: 18px;
      border: 1px solid #EA4232;
      background-color: white;
    }
  }
}

@media only screen and (max-width: 480px) and (min-width: 390px) {
  .slider-service {
    padding: 12px;

    .service-head {
      font-family: "Poppins";
      font-size: 22px;
      font-weight: 700;
      line-height: 55px;
      text-align: left;
      margin-bottom: 40px;
    }
  }
}