.Faq {
  background: #fff9f3;
  padding: 41px 90px;

  .head {
    font-family: "Poppins";
    font-size: 45.5px;
    font-weight: 600;
    line-height: 60px;
    text-align: left;
    color: #5B8E3E;
    margin-bottom: 60px;
  }

  .MuiAccordion-root {
    margin-bottom: 20px;
    box-shadow: none;
    border: 1px solid #1d243414;
    border-radius: 12px;

    .MuiAccordionSummary-root {
      padding: 25px !important;

      .MuiAccordionSummary-content {
        margin: 0;
        .MuiTypography-root {
          font-family: "Poppins";
          font-size: 15.61px !important;
          font-weight: 600 !important;
          line-height: 24px !important;
          color: #000000 !important;
        }
      }
    }

    .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
      color: #000000 !important;
    }

  

    .MuiCollapse-root {
      .MuiAccordionDetails-root {
        padding: 0px 25px 25px 25px;

        .text {
          font-family: "Poppins";
          font-size: 13.75px;
          font-weight: 400;
          line-height: 24px;
          color: #000000;
          margin-bottom: 15.5px;
        }

        li {
          list-style: none;
        }

        .sm-text {
          font-family: "Poppins";
          font-size: 13.75px;
          font-weight: 400;
          line-height: 24px;
          color: #000000;
        }
      }
    }
  }
  
  .accordian-faq {
    width: 718px;
  }



  .contact{
    width: 432px;
  }

  .int-head {
    width: 316.12px;
    font-family: "Poppins";
    font-size: 27.25px;
    font-weight: 600;
    line-height: 40px;
    color: #5B8E3E;
    margin-bottom: 40px;

    span {
      font-family: "Poppins";
      font-size: 27.25px;
      font-weight: 600;
      background: linear-gradient(90deg, #febf24 0%, #ea4232 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      color: transparent;
    }
  }

  .input-text-container {
    display: flex;
    align-items: center;
    gap: 7.8px;
    border: 1px solid #ea4232;
    padding: 13px 25px;
    border-radius: 25px;
    width: 480px;
    height: 46px;
    margin-bottom: 20px;
  }

  .sm-input-text{
    display: flex;
    align-items: center;
    font-family: "Poppins";
        font-size: 16.03px;
        font-weight: 500;
        line-height: 24px;
        color: #EA4232;
        width: 150px;

        svg{
          margin-right: 1rem;
        }
  }

    

  .form-control {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    border: none;
    background: transparent;
    color: #1d243485;
  }

  .form-control:focus{
    border-color: transparent;
    box-shadow: none;
    background-color: transparent;
  }

  ::placeholder {
    color: #1d243485;
    font-size: 16px;
  }
}

.faq-form{
  background: #FFF9F3;
padding: 71.5px 72px ;
  margin-top: 33px;
  margin-bottom: 105px;

  .txt{
    font-family: "Poppins";
      font-size: 30px;
      font-weight: 700;
      line-height: 35px;
      text-align: center;
      color: #5B8E3E;
      margin-bottom: 53.3px;
  }

  .row{
    margin-bottom: 30px;
  }

     .form-control {
       border: 1px solid #febf24;
       height: 50px;
       border-radius: 20px;
       font-family: "Poppins";
       font-size: 16px;
       font-weight: 300;
       line-height: 22.4px;
       letter-spacing: -0.02em;
       padding: 12px 32px;
       color: #000000;
       background: transparent;
     }
  
     .form-control:focus {
       box-shadow: none;
     }
  
     ::placeholder {
       color: #9CA3AF;
     }

            .textarea {
              height: 246px;
              padding: 30px 40px;
            }
      
            .textarea:focus {
              background: transparent;
              border-color: #febf24;
            }

            .message-now{
              display: flex;
              justify-content: center;
              width: 247px;
                height: 50px;
                padding: 14.5px 51.44px 15.5px 51.73px;
                border-radius: 25px;
                border: none;
                background: linear-gradient(92.04deg, #FDBD24 0%, #EF6331 100%);
                font-family: "Poppins";
                  font-size: 16px;
                  font-weight: 700;
                  line-height: 20px;
                  letter-spacing: 0.800000011920929px;
                  text-align: center;
                  color: #FFFFFF;
            }

            a:-webkit-any-link {
              color: white;
              text-decoration: none;
              }
}

@media only screen and (max-width: 480px) and (min-width: 390px){
  .Faq{

    .head{
      font-size: 22px;
      margin-bottom: 26px;
    }
    padding: 40px;
    .contact{
      width: auto;
    }

    .input-text-container{
      width: 350px;
      padding: 13px;
    }

        .sm-input-text svg{
          margin-right: 6px;
        }

        .sm-input-text{
          font-size: 16px;
          width: 140px;
        }

        .form-control{
          ::placeholder{
            font-size: 13px !important;
          }
        }
  }

  .faq-form{
    padding: 20px;

    .txt{
      font-size: 22px;
      margin-bottom: 40px;
    }

    .row{
      margin: 0;
      flex-direction: column;
    }

    .col-6{
      margin-bottom: 16px;
      max-width: none;
    }

    .message-now{
      width: fit-content;
      font-family: "Poppins";
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.800000011920929px;
        text-align: center;
        margin-top: 51px;
        margin-bottom: 24px;
    }
  }
}
