.CompanyWorking {
    .sub-head {
        padding-left: 104px;
        display: flex;
    }

    .dash {
        width: 69px;
        height: 5px;
        background: linear-gradient(225deg, #FEBF24 0%, #EA4232 100%);
        margin-bottom: 20px;
    }

    .head-box {
        width: 80%;
    }

    .head-1 {
        // font-family: "Inter";
        font-size: 35px;
        font-weight: 400;
        line-height: 55px;
        color: #5B8E3E;
    }

    .head-2 {
        // font-family: "Inter";
        font-size: 35px;
        font-weight: 700;
        line-height: 55px;
        color: #5B8E3E;
        margin-bottom: 1rem;
    }

    .btn-box {
        width: 20%;


        .btn {
            margin: 0;
            padding: 0;
        }

        .btn2 {
            background: none;
            border: none;
            padding-top: 1.5rem;
            margin: 0;
        }
    }

    svg:focus {
        outline: none;
    }

    .btn:focus {
        box-shadow: none;
    }

    .circle-large {
        position: absolute;
        top: -400px;
        left: -50px;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background: linear-gradient(225deg, #FEBF24 0%, #EA4232 100%);
        z-index: -1;
    }

    .cards-container {
        display: flex;
        overflow: auto;
        align-items: center;
        gap: 54px;
        background: #FFF9F3;
        margin: auto;
        justify-content: center;
        padding: 10px;
        border: 1px solid;
        border-image-source: linear-gradient(90deg, #FEBF24 0%, #EA4232 100%);
        border-image-slice: 1;
    }

    .imgcontainer {
        display: flex;

    }

    .img-banner {
        width: 150px;
        height: 100px;
        object-fit: contain;
    }

    // .cards-container::-webkit-scrollbar {}

    /* Hide scrollbar for IE, Edge, and Firefox */
    .cards-container {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }


}

@media only screen and (max-width: 480px) and (min-width: 390px) {
    .CompanyWorking {
        margin-top: 76px;

        .sub-head {
            padding-left: 21px;
            align-items: center;
        }

        .head-1 {
            font-family: "Poppins";
            font-size: 22px;
            font-weight: 400;
            line-height: 30px;
            text-align: left;
        }

        .head-2 {
            font-family: "Poppins";
            font-size: 22px;
            font-weight: 700;
            line-height: 30px;
            text-align: left;
        }

        .btn-box {
            width: 30%;
            display: none;
        }
    }
}