.HirePage {
  background-color: #fff9f3;
  padding: 51px 90px;
  width: 100%;
  min-height: 100%;
display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
clip-path: polygon(0 0, 101% 0, 100% calc(100% - 70px), 50% 100%, 0 calc(100% - 70px));

    .heading-main{
        text-align: center !important;
        font-size: 46.11px;
        margin-bottom: 60.5px;
    }

  .HirePage-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 35px;
  }

  .hireCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 282.75px;
    // height: 432px;
    padding: 16px;
    border: 1px solid #febf24;
    background: white;
    border-radius: 16px;

  }

  .img-card{
    width: 120px;
        height: 120px;
        margin-bottom: 9.5px;
  }

  .name {
    font-family: Poppins;
    font-size: 19.88px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    color: #5B8E3E;
    margin-bottom: 4.5px;
  }

  .title {
    font-family: Poppins;
    font-size: 14.38px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #1d24349e;
    margin-bottom: 12px;
  }

  .pay {
    display: flex;
    gap: 11px;
    margin-bottom: 12px;

    span {
      font-family: Poppins;
      font-size: 12.36px;
      font-weight: 700;
      line-height: 22px;
      color: #ea4232;
    }
  }

  .filled {
    padding: 6px;
    border: 1px solid #e9ebf1;
    border-radius: 50px;
  }

  .txt-1 {
    font-family: "Poppins";
    font-size: 12.36px;
    font-weight: 400;
    line-height: 22px;
    color: #ea4232;
  }

  .txt-2 {
    font-family: "Poppins";
    font-size: 12.25px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #1d2434;
  }

  .rate {
    font-family: "Poppins";
    font-size: 12.36px;
    font-weight: 700;
    line-height: 25px;
    margin-top: 8px;
    margin-left: 5px;
    margin-right: 5px;
    color: #1d2434 !important;
  }

  .stars{
    font-weight: 900;
  }

  .space-bott {
    margin-bottom: 19.5px;
  }

  .skills {
    width: 156.03px;
    font-family: "Poppins";
    font-size: 11.59px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #1d24349e;
    margin-bottom: 20.5px;
  }

  .hire-btn {
    border: 1px solid #ea4232;
    font-family: "Poppins";
    font-size: 26px;
    font-weight: 600;
    line-height: 24px;
    color: #ea4232;
    border-radius: 50px;
    background: transparent;
    width: 250.75px;
    height: 48px;
  }

  .health-submit {
      font-family: "Poppins";
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      display: flex;
      margin: auto;
      margin-top: 86px;
      padding: 19px 40px;
      background: linear-gradient(90deg, #FEBF24 0%, #EA4232 100%);
      color: #FFF9F3;
      border: none;
      border-radius: 50px;
  }
}

@media only screen and (max-width: 480px) and (min-width: 340px) {
   
  .HirePage  {
    width: 100% !important;
    padding: 51px 10px !important;


    .HirePage-card{
      overflow-x: auto;
      height: 70vh;
    }
    
    .health-submit{
      padding: 5px 16px;
      margin-top: 40px;
    }

    .heading-main{
      font-size: 22px;
    }
    
    .heading-color{
      font-size: 22px !important;
    }
  }

}