.Connect-hire {
  display: flex;

  .form-hire-page {
    padding: 62px 41px 62px 50px;
    // width: 916px;
    width: 100%;
  }

  .heading-main {
    font-size: 31.36px !important;
    margin-bottom: 56.5px;
  }

  .heading-color {
    font-size: 31.36px !important;
  }

  .hire-img {
    object-fit: cover;
    // height: 1023px;
    width: 100%;
  }

  .info {
    font-family: "Poppins";
    font-size: 15.47px;
    font-weight: 600;
    line-height: 24px;
    color: #212529;
    margin-bottom: 12px;
  }

  .from-data {
    margin-bottom: 19px;
  }

  label {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #1d2434b8;
    margin-bottom: 4px;
  }

  .form-control {
    font-family: "Inter";
    border: 0.5px solid #febf24;
    // border-image-slice: 1;
    // border-image-source: linear-gradient(180deg, #FEBF24 0%, #EA4232 100%);
    border-radius: 8px;
    height: 45px;
    padding: 11px 12px 11px 24px;
    font-size: 14px;
    color: #252525 !important;
  }

  ::placeholder {
    font-size: 14px;
    //  color: #252525 !important;
  }

  .form-control:focus {
    background-color: #fff;
    border-color: #febf24;
    outline: 0;
    box-shadow: none;
  }

  .ind-input {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .countrycode {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Poppins";
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    color: #000000de;
    width: 76.38px;
    padding: 12px;
    border-top-right-radius: 0px !important;
    height: 45px;
    border: 0.5px solid #febf24;
    border-radius: 8px;
    border-bottom-right-radius: 0px !important;
  }

  .flag {
    margin-right: 2px;
    width: 23px;
    height: 15px;
  }

  .job-card {
    display: flex;
    gap: 63px;
  }

  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 177px;
    height: 120px;
    border-radius: 12px;
    border: 1px solid #febf24;
  }

  .txt {
    margin-top: 19px;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: center;
    color: #0000006b;
  }

  .skills {
    margin-top: 28px;
  }

  .skills-head {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #1d24346b;
    margin-bottom: 8px;
  }

  .search {
    font-family: "Poppins";
    font-size: 11.81px;
    font-weight: 400;
    line-height: 24px;
    color: #1d24346b;
  }

  .trend {
    margin-top: 10px;
  }

  .search {
    margin-bottom: 4px;
  }

  .box-skilss {
    width: 114.31px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #febf24;
    font-family: "Poppins";
    font-size: 12.25px;
    font-weight: 500;
    line-height: 21px;
    color: #212529;
  }

  .lets-connect {
    margin-top: 33px;
    width: 730px;
    height: 52px;
    border-radius: 50px;
    border: none;
    background: #ea4232;
    font-family: "Poppins";
    font-size: 16.73px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }
}

@media only screen and (max-width: 480px) and (min-width: 300px) {
  .Connect-hire {
    flex-direction: column !important;

    .hire-parent {
      order: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;
    }
    

    .form-hire-page {
      width: 350px;
      padding: 20px;
      box-shadow: 0px 25px 50px -12px rgba(13, 16, 45, 0.1);
      box-shadow: 0px 12px 24px 0px rgba(13, 16, 45, 0.1);
      border-radius: 9px;
      position: absolute;
      top: 85vh;
      z-index: 10;
      background: #FFFAF5;
    }

    div {
      order: -1;
    }

    .hire-img {
      width: 100%;
      position: fixed;
    }

    .heading-main,
    .heading-color {
      line-height: 30px;
      font-size: 22px !important;
      font-weight: 500;
      margin-bottom: 24px;
    }

    .info{
      margin-bottom: 12px;
        font-size: 15.47px;
        font-weight: 600;
        line-height: 24px;
    }
    
    .mail-phone{
      display: flex;
      flex-direction: column;

      .col-6{
        max-width: none;
      }
    }

    .job-card{
      gap: 27px;
      flex-wrap: wrap;
      justify-content: center;
    }

    .box{
      width: 141px;
        height: 68px;
        border-radius: 12px;

        svg{
          width: 30px;
          height: 30px;
        }

        .txt{
          margin-top: 6px;
          font-size: 14px;
        }
    }

    .languages-card{
      flex-wrap: wrap;
      gap: 8px !important;
    }

    .lets-connect{
    width: 168px;
      height: 40px;
      border-radius: 11px;
      

    }
  }
}
