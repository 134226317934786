.ContactUsImg {
  padding: 60px 125px 60px 100px;
  // background: linear-gradient(119.07deg, #fff7e0 7.82%, #f2d3c9 84.24%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../assests/Rectanglecurve.png");

  .heading-color {
    margin-bottom: 35px;
  }

  .para {
    width: 622.25px;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #1d2434b8;
  }

  .serviceonetxt {
    font-family: "Poppins";
    font-size: 44px;
    font-weight: 400;
    letter-spacing: -0.25px;
    text-align: left;
    color: #5B8E3E;
  }

  .txt {
    color: #ea4232;
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    margin-bottom: 56px;
  }

  .service-contact {
    margin-top: 45px;
    width: 179.03px;
    height: 54px;
    padding: 0px 29.67px 0px 30px;
    border-radius: 27.5px;
    background: #ea4232;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 0.5px;
    text-align: center;
    border: none;
    color: white;
  }

  .service-txt {
    font-family: "Poppins";
    font-size: 36px;
    font-weight: 700;
    line-height: 53px;
    letter-spacing: 0.02em;
    color: #5B8E3E;
  }

  .input-field {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    width: 313.23px;
    height: 50px;
    padding: 13px 167.23px 13px 16px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    outline: none;
    color: #000000;

    ::placeholder {
      color: #6c757d;
    }
  }

  .send-btn {
    border: none;
    width: 152.42px;
    height: 50px;
    padding: 14.5px 17.99px 15.5px 25px;
    border-radius: 0px 4px 4px 0px;
    background: #ea4232;
    font-family: "Poppins";
    font-size: 15.63px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
  }

  .contactusimg {
    width: 447px;
    height: 450px;
    border-radius: 20px;
    object-fit: contain;
  }

  .join {
    width: 442px;
    margin-bottom: 18px;
    font-family: "Poppins";
    font-size: 53.89px;
    font-weight: 600;
    line-height: 64.67px;
    letter-spacing: -0.03em;
    color: #5B8E3E;
  }

  .paras {
    margin-bottom: 25px;
  }

  .svg-star {
    position: absolute;
    z-index: 1;
    top: 14vh;
    right: 4vh;
    width: 40%;
    height: 60%;
  }

  .star-box {
    width: 50%;
    height: 65vh;
  }

  .svg-starimg {
    width: 30%;
    border-radius: 10px;
    position: absolute;
    z-index: 2;
    right: 13vh;
    top: 20vh;
  }

  .heading-color {
    line-height: 53px !important;
    text-align: left !important;
    margin-bottom: 31px;
  }

  .heading-color-about {
    font-family: "Poppins";
    font-size: 53.89px;
    font-weight: 600;
    line-height: 53px !important;
    text-align: left !important;
    margin-bottom: 31px;
    background: linear-gradient(270deg, #ea4232 45%, #febf24 100%);
    -webkit-background-clip: text !important;
    background-clip: text !important;
    -webkit-text-fill-color: transparent;
    color: transparent;
  }

  .para-about {
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #5B8E3E;
    width: 501.3px;
    margin-bottom: 57px;
  }

  .enquire {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 225.02px;
    height: 50.95px;
    border-radius: 25.47px;
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 700;
    line-height: 24.03px;
    background: #ea4232;
    text-align: left;
    color: #ffffff;
    border: none;
  }

  //  .aboutimg {
  //    width: 517.88px;
  //    height: 536.64px;
  //    border-radius: 213.53px 0px 218.78px 216px;
  //    object-fit: cover;
  //  }
}

@media only screen and (max-width: 480px) and (min-width: 390px) {
  .ContactUsImg {
    padding: 50px 21px 33px 19px;
    flex-direction: column;
    align-items: flex-start;

    .one-order{
      order: 0;
    }

    .two-order{
      display: flex;
      margin: auto;
      order: -1;
    }

    .contactusimg-carrier{
      width: 328px;
    }

    .aboutimg, .service-oferr-mob-img {
      width: 350px;
      height: 339.95px;
      object-fit: contain;
    }

    .heading-color-about{
      font-size: 22px;
      margin: 0;
    }

    .serviceonetxt{
      font-size: 30px;
    }

    .service-contact{
      margin-top: 20px;
      width: auto;
    }

    .para-about{
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        width: 350px;
        margin-bottom: 13px;
    }

    .enquire{
      width: 153px;
        height: 34px;
        border-radius: 11px;
        font-size: 14px;
        font-weight: 700;
        line-height: 18.69px;
    }

    .contact-box{
    order: 0;
    width: 100%;
    }

    .star-box{
      width: 100%;
      height: 42vh;
      order: -1;
    }

    .svg-star{
      width: 94%;
      top: 20vh;
      right: 0;
    }

    .svg-starimg{
      width: 74%;
      right: 6vh;
    }

    .heading-color{
        font-size: 22px;
        font-weight: 600;
        line-height: 53px;
        text-align: left;
        margin-bottom: 3px;
    }

    .join{
      font-size: 18px;
      width: 100%;

span{
      font-size: 18px;
}

    }

    .para{
      width: 100%;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
    }

    .txt{
      font-size: 12px;
      margin-bottom: 11px;
    }

    .input-field{
          width: 197.23px;
          padding: 13px 0px 13px 16px;
    }

    .send-btn{
      font-size: 14px;
    }

    .privacy-one{
      order: 0;
    }

    .privacy-two{
      order: -1;

      img{
        width: 350px;
          height: 310.89px;
      }
    }

    .service-txt{
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0.02em;
        text-align: left;

    }
  }
}
