.Glimpse {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;

  .sm-team {
    // border: 1px solid #ef6031;
    text-transform: uppercase;
    padding: 7px 24px;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #1d243485;
    margin-bottom: 12px;
    border-radius: 40px;
  }

  .b-head {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 30px;
    color: #5B8E3E;
    margin-bottom: 12.5px;
  }

  .s-head {
    width: 60%;
    font-family: "Poppins";
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #626a72;
  }

  .cards-container {
    overflow-x: auto;
    /* Enables horizontal scrolling */
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    /* Prevents wrapping of items */
    // gap: 30px;
  }

  /* Hide scrollbar for Chrome, Safari, and Opera */
  .cards-container::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  .cards-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .container {
    // width: 490px;
    height: 506px;
    position: relative;
  }

  .img-container {
    position: relative;
    object-fit: cover;
    // width: 490px;
    height: 445px;
    border-radius: 20px !important;
    background: white;
    margin-top: 20px;
  }

  .img-container::before {
    content: "";
    position: absolute;
    top: -1px;
    left: -2px;
    right: -2px;
    bottom: -1px;
    border-radius: 20px;
    background: linear-gradient(180deg, #FEBF24 0%, #EA4232 100%);
    z-index: -1;
  }

  .img-banner {
    width: 100%;
    height: 445px;
    // object-fit: contain;
    border-radius: 20px;


  }

  .img-container::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: linear-gradient(to top, rgba(237, 228, 228, 0.5), rgba(234, 229, 229, 0) 50%);
  }

  .sm-container {
    position: absolute;
    bottom: 61px;
    padding: 20px;
  }

  .inner-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background: #FCFDFD;
    width: 400px;
    height: 106px;
    border-radius: 16px;
    box-shadow: 0px 0px 0px 4px #FFFFFF;
    border: 1px solid #EA4232;
  }

  .name {
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.006em;
    color: #5B8E3E;
  }

  .tittle {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #5B8E3E;
  }

  .circle {
    cursor: pointer;
    width: 58px;
    height: 58px;
    border-radius: 100px;
    border: 1px solid #EA4232;
    padding: 8.69px;
  }

  .inner-circle {
    background: linear-gradient(180deg, #FEBF24 -178.57%, #EA4232 169.05%);
    width: 40.63px;
    height: 40.63px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .project {
    border: none;
    width: Hug (224px)px;
    height: Hug (62px)px;
    padding: 14px 30px 14px 14px;
    border-radius: 100px;
    background: linear-gradient(180deg, #EA4335 0%, #FEBF24 100%);
    color: #FFFFFF;
    // font-family: "Inter";
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
  }
}


@media only screen and (max-width: 480px) and (min-width: 390px) {
  .Glimpse {

    .sm-team {
      font-size: 10px;
    }

    .b-head {
      width: 336px;
      font-size: 22px;
    }

    .s-head {
      font-size: 12px;
      width: 350px;
    }

    .cards-container {
      display: flex;
      margin-bottom: 72px;
      flex-direction: column;
      /* Switch to column layout */
      overflow-y: auto;
      /* Enable vertical scrolling */
      // max-height: 100vh;
      max-height: 836px;
      /* Limit height to screen size */
      gap: 20px;
      /* Adjust gap between cards */
      padding: 10px;
    }

    /* Hide scrollbar for Chrome, Safari, and Opera */
    .cards-container::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge, and Firefox */
    .cards-container {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }

    .container {
      width: 100%;
      /* Full width for smaller screens */
      height: auto;
      /* Allow height to adjust with content */
      margin-bottom: 20px;
      /* Add spacing between cards */
    }

    .img-container {
      width: 100%;
      // height: auto;
      height: 364px;
      /* Maintain aspect ratio of the image */
      border-radius: 15px !important;
      /* Smaller border radius */
      margin-top: 10px;
    }

    .img-container::before {
      border-radius: 15px;
    }

    .img-banner {
      width: 100%;
      height: auto;
      /* Responsive height */
      object-fit: cover;
      /* Cover the container properly */
      border-radius: 15px;
    }

    .img-container::after {
      border-radius: 15px;
    }

    .sm-container {
      width: 94%;
      display: flex;
      justify-content: center;
      bottom: 14px;
      /* Adjust bottom space */
      padding: 0px;
      /* Smaller padding */
    }

    .inner-container {
      width: 300px;
      /* Full width for smaller screens */
      padding: 15px;
      /* Reduced padding */
      height: fit-content;
      /* Let height adjust */
      border-radius: 12px;
      border: 1px solid #EA4232;
    }

    .name {
      font-size: 18px;
      /* Adjust font size for smaller screens */
      line-height: 26px;
    }

    .tittle {
      font-size: 14px;
      line-height: 22px;
    }

    .circle {
      width: 50px;
      /* Smaller circle for mobile */
      height: 50px;
      padding: 6px;
      border-radius: 50%;
    }

    .inner-circle {
      width: 35px;
      /* Adjusted size for inner circle */
      height: 35px;
    }

  }
}