.JobHired{
    display: flex;
    width: 100%;

    .img-div{
position: relative;
width: 40%;
    }
 
    .jobimg{
        object-fit: contain;
        width: 100%;
        // width: 720px;
            // height: 1168px;
    }

    .availibility{
        display: flex;
        flex-wrap: wrap;
    }

    .text{
        position: absolute;
        top: 100px;
        left: 20%;
    }

    .text-1{
        margin-bottom: 40px;
        font-family: "Poppins";
            font-size: 34px;
            font-weight: 700;
            line-height: 38.4px;
            color: #FFFFFF;
    }

    .text-2{
        width: 352px;
        font-family: "Poppins";
            font-size: 22px;
            font-weight: 400;
            line-height: 26px;
            color: #FFFFFF;
            margin-bottom: 16px;
    }
    
    .form-job{
            width: 60%;
        padding: 47px 25px 47px 25px;
    }

    .job-txt{
        font-family: "Poppins";
            font-size: 31.36px;
            font-weight: 600;
            line-height: 54px;
            color: #212529;
            margin-bottom: 46px;
    }

         .from-data,.row {
             margin-bottom: 19px !important;
         }
    
         label {
             font-family: Poppins;
             font-size: 16px;
             font-weight: 500;
             line-height: 24px;
             color: #1d2434b8;
             margin-bottom: 4px;
         }
    
         .form-control {
             font-family: "Inter";
             border: 0.5px solid #febf24;
             // border-image-slice: 1;
             // border-image-source: linear-gradient(180deg, #FEBF24 0%, #EA4232 100%);
             border-radius: 8px;
             height: 45px;
            //  width: 300px;
             padding: 11px 12px 11px 24px;
             font-size: 14px;
             color: #252525 !important;
         }
    
         ::placeholder {
             font-size: 14px;
             //  color: #252525 !important;
         }
    
         .form-control:focus {
             background-color: #fff;
             border-color: #febf24;
             outline: 0;
             box-shadow: none;
         }
    
         .ind-input {
             border-left: none;
             border-top-left-radius: 0px;
             border-bottom-left-radius: 0px;
         }
    
         .countrycode {
             display: flex;
             justify-content: space-between;
             align-items: center;
             font-family: "Poppins";
             font-size: 15px;
             font-weight: 500;
             line-height: 18px;
             color: #000000de;
             width: 76.38px;
             padding: 12px;
             border-top-right-radius: 0px !important;
             height: 45px;
             border: 0.5px solid #febf24;
             border-radius: 8px;
             border-bottom-right-radius: 0px !important;
         }
    
         .flag {
             margin-right: 2px;
             width: 23px;
             height: 15px;
         }

                  .card {
                      border: none;
                  }

                   .p-dropdown {
                       border: 0.5px solid #febf24;
                       border-radius: 8px;
                       height: 45px;
                       padding: 11px 12px 11px 24px;
                   }
        
                   .p-inputtext {
                       font-family: "Inter";
                       font-size: 14px;
                       color: #252525 !important;
                   }

                   .resume{
                    margin-top: 16px;
                    margin-bottom: 28px;
                    font-family: "Poppins";
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                        text-align: left;
                            color:#000000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 100%;
                        height: 51px;
                        border-radius: 12px;
                        border: 1px dashed #EA4232;
                   }

                   .red{
                    color: #EA4232;
                   }

                   .small-text{
                    font-family: "Poppins";
                        font-size: 10px;
                        font-weight: 400;
                        line-height: 15px;
                        color: #676767;
                   }

                   .radio-div{
                    gap: 22px;
                    padding: 8px 14px;
                        height: 40px;
                        border-radius: 12px;
                        border: 1px solid #FEBF24;
                   }

                   .time{
                    font-family: "Poppins";
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        color: #EA4232;
                   }

                   .radio{
                    accent-color: #EA4232;
                   }

                   .drem-job{
                    display: flex;
                    margin: auto;
                    margin-top: 40px;
                    border: none;
                    padding: 13.67px 54.84px 9.33px 53.16px;
                    font-family: "Poppins";
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 27px;
                        text-align: center;
                        color: #FFFFFF;
                        background: linear-gradient(90deg, #FEBF24 0%, #EA4232 100%);
                        border-radius: 80px;

                   }
}

@media only screen and (max-width: 480px) and (min-width: 300px) {  
    .JobHired{
        flex-direction: column;

        .img-div{
            width: 100%;
            position: fixed;
            top: 0;
        }

        .jobimg{
            width: 100%;
        }

        .form-job{
            background: white ;
            width: 95%;

            position: absolute;
            z-index: 10;
            top: 60vh;
            margin: 0px 10px;
            border-radius: 9px;
            box-shadow: 0px 25px 50px -12px #0D102D1A;

box-shadow: 0px 12px 24px 0px #0D102D1A;

        }

        .row{
            flex-direction: column !important;
        }

        .col-6{
            max-width: 100% !important;
        }

        .form-control{
            margin: 10px 0px !important;
        }

        .p-dropdown{
            margin: 10px 0px !important;
        }

        .resume{
            height: fit-content !important;
            padding: 10px;
        }
    }
}