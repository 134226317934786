.ContactUsForm {
  padding: 99px 100px 110px 100px;

  .main-card {
    border: 1px solid #febf24;
    padding: 62px 50px;
    background: #fff9f3;
    border-radius: 36px;
  }

  .main-box {
    display: flex;
    gap: 24px;
    margin-bottom: 99px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .box {
    padding: 60px 22px 17px 22px;
    width: 364px;
    height: 287px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 20px;
    border: 1px solid #febf24;
  }

  .txt1 {
    font-family: "Poppins";
    font-size: 28px;
    font-weight: 500;
    line-height: 39.2px;
    letter-spacing: -0.02em;
    margin-top: 30px;
    color: #5B8E3E;
  }

  .txt2 {
    width: 320;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 300;
    line-height: 22.4px;
    letter-spacing: -0.02em;
    margin-top: 16px;
    color: #ea4232;
    text-align: center;
  }

  .send-mess {
    font-family: "Poppins";
    font-size: 48px;
    font-weight: 600;
    line-height: 57.6px;
    letter-spacing: -0.03em;
    text-align: center;
    color: #5B8E3E;
    margin-bottom: 64px;
  }

  .form-control {
    border: 1px solid #febf24;
    height: 82px;
    border-radius: 12px;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 300;
    line-height: 22.4px;
    letter-spacing: -0.02em;
    color: #000000;
    background: transparent;
  }

  .form-control:focus {
    box-shadow: none;
  }

  ::placeholder {
    color: #929292;
  }

  .row {
    margin-bottom: 30px;
  }

  .textarea {
    height: 246px;
    padding: 30px 40px;
  }

  .textarea:focus {
    background: transparent;
    border-color: #febf24;
  }

  .send {
    width: 100%;
    height: 53px;
    padding: 20px 28px 20px 28px;
    border-radius: 99px;
    border: none;
    background: #ea4232;
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #ffff;
  }

  .stats {
    margin-top: 110px;
  }

  .heading-color {
    font-weight: 400;
    font-size: 96px !important;
    margin-bottom: 64px;
    text-align: center;
  }

  .stats-card {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .stats-box {
    width: 555px;
    height: 284px;
    padding: 40px 32px 40px 33px;
    border-radius: 20px;
    border: 1px solid #ea4232;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff9f3;
  }

  .num {
    font-family: "Poppins";
    font-size: 64px;
    font-weight: 400;
    line-height: 76.8px;
    letter-spacing: -0.03em;
    text-align: center;
    color: #5B8E3E;
    margin-bottom: 20px;
  }

  .plus {
    font-family: "Poppins";
    font-size: 64px;
    font-weight: 400;
    background: linear-gradient(180deg, #febf24 0%, #ea4232 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
  }

  .stats-txt {
    font-family: "Poppins";
    font-size: 36px;
    font-weight: 400;
    line-height: 43.2px;
    letter-spacing: -0.03em;
    text-align: center;
    color: #5B8E3E;
    margin-bottom: 20px;
  }

  .stats-para {
    width: 334px;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 300;
    line-height: 22.4px;
    letter-spacing: -0.02em;
    text-align: center;
  }
}

.contactus-schdulecall{
        justify-content: center;
            display: flex;
    padding: 0px 70px 110px 70px;

    .schdulecall-card{
        border: 1px solid #FEBF24;
        border-radius: 30px;
        padding: 24px;
        width: 1300px;
            height: 580px;
    }

    .inner-card{
        padding: 100px 314px 55px 314px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: #FFF9F3;
        border-radius: 20px;
    }

    .heading-color{
        width: 624px;
        font-size: 64px !important;
        font-weight: 400;
        margin-bottom: 20px;
        text-align: center;
    }

    .para{
        width: 624px;
            font-family: "Poppins";
            font-size: 16px;
            font-weight: 300;
            line-height: 22.4px;
            letter-spacing: -0.02em;
            text-align: center;
            color: #5B8E3E;
            margin-bottom: 40px;
    }

    .call-btn{
        width: Hug (216px)px;
            height: Hug (53px)px;
            padding: 20px 28px 20px 28px;
            border-radius: 99px;
            border: none;
                font-family: "Poppins";
                font-size: 18px;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: -0.02em;
                text-align: center;
                color: #FFFFFF;
                background: #EA4232;
    }
}

@media only screen and (max-width: 480px) and (min-width: 390px){
  .ContactUsForm{
    padding: 60px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .main-card{
      padding: 30px 0;
    }

    .main-box{
      flex-direction: column;
      gap: 20px;
      margin-bottom: 53px;
    }

    .box{
      width: 245px;
      height: 161px;
      padding: 16px 16px;
    }

    .icon-div{
      svg{
        width: 40px;
          height: 40px;
          margin-bottom: 10px;
      }
    }

    .txt1{
      margin: 0;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: -0.02em;
        text-align: center;
    }

    .txt2{
      margin: 0;
        font-size: 10px;
        font-weight: 400;
        line-height: 19.6px;
        letter-spacing: -0.02em;
        text-align: center;
    }

    .send-mess{
      margin-bottom: 39px;
        font-size: 22px;
        font-weight: 600;
        line-height: 26.4px; 
        letter-spacing: -0.03em;
        text-align: center;
    }

    .col-6{
      max-width: none;
      flex: none;
      margin-bottom: 15px;
    }

    .row{
      margin: 0;
    }

    .form-control{
      width: 309px;
        height: 49px;
        border-radius: 12px;
    }

    .textarea{
      height: 144px;
    }

    .send{
      display: flex;
      width: auto;
      border-radius: 11px;
      padding: 14px 25px;
      margin: auto;
      margin-top: 43px;
    }

    .stats{
      margin-top: 60px;
    }

    .heading-color{
      margin-bottom: 42px;
      font-size: 22px !important;
    }

    .stats-card{
      font-size: 16px;
    }

    .stats-box{
      padding: 16px;
      width: 253px;
        height: 152px;
        border-radius: 20px;
    }

    .num{
        font-size: 32px;
        font-weight: 400;
        line-height: 38.4px;
        letter-spacing: -0.03em;
        text-align: center;
        margin-bottom: 7px;
    }

    .plus{
        font-size: 32px;
          font-weight: 400;
          line-height: 38.4px;
          letter-spacing: -0.03em;
          text-align: center;
    }

    .stats-txt{
        font-size: 22px;
        font-weight: 400;
        line-height: 26.4px;
        letter-spacing: -0.03em;
        text-align: center;
        margin-bottom: 15px;
    }

    .stats-para{
        font-size: 12px;
        font-weight: 400;
        line-height: 16.8px;
        letter-spacing: -0.02em;
        text-align: center;
        width: 100%;
    }
  }

  .contactus-schdulecall{
    padding: 24px 11px;

    .schdulecall-card{
      width: 100%;
      height: 100%;
    }

    .inner-card{
      padding: 0;
    }

    .heading-color{
      width: 100%;
      margin-top: 15px;
        font-size: 22px !important;
        font-weight: 400;
        line-height: 26.4px;
        letter-spacing: -0.03em;
        text-align: center;
    }

    .para{
      width: 100%;
        font-size: 12px;
        font-weight: 400;
        line-height: 16.8px;
        letter-spacing: -0.02em;
        text-align: center;
    }

    .call-btn{
      font-size: 16px;
      padding: 12px;
      margin-bottom: 15px;
    }
  }
}
