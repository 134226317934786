@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import url("./homepage/index.scss");
@import url("./healthcare/index.scss");
@import url("./navbar/navbar.scss");
@import url("./faq/faq.scss");
@import url("./footer/footer.scss");
@import url("./hire/hire.scss");
@import url("./job/jobHired.scss");
@import url("./project/index.scss");
@import url("./aboutus/index.scss");
@import url("./contactus/index.scss");
@import url("./privacy/privacy.scss");
@import url("./service/service.scss");
@import url("./serviceone/serviceOne.scss");
@import url("./Developer/developer.scss");
@import url("./carrier/carrierPage.scss");
@import url("./ProjectMore/projectMore.scss");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


// #root{
// overflow-y: auto;
// width: 100%;
// height: fit-content;
// scrollbar-width: none;
// }

*::-webkit-scrollbar {
  display: none;
}

.heading-main {
  margin-bottom: 28px;
  font-family: "Poppins";
  font-size: 53.89px;
  font-weight: 600;
  line-height: 66px;
  text-align: left;
  color: #5B8E3E;
}

.heading-color {
  font-family: "Poppins";
  font-size: 53.89px;
  font-weight: 600;
  background: linear-gradient(90deg, #febf24 0%, #ea4232 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.mandatory {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #ea4232;
}

//   .p-dropdown-label-container{
//     display: flex;
//     justify-content: space-between;
// }

.p-dropdown-panel {
  background: white !important;
  padding: 10px;
  height: 20vh;
  overflow: auto;
  border-radius: 10px;
  padding-left: 25px;
}

// .p-dropdown-trigger{
//     margin-left: 5px !important;
//     margin-top: 4px !important;
// }

// .p-dropdown-items-wrapper {
//     background-color: white !important;
//     margin-top: 10px;
//     scrollbar-width: none; 
//     -ms-overflow-style: none;  
//     border-radius: 10px;
//     width: 100%;
// }

// .p-dropdown-items-wrapper::-webkit-scrollbar {
//     display: none; 
// }

// .p-dropdown-items{
//     display: flex !important;
//     flex-wrap: wrap;
// }

// .p-dropdown-item-label{
//     margin: 10px;
//     font-size: smaller;
//     display: flex;
// }

.float {
  position: fixed;
  top: 85vh;
  right: 5vh;
  z-index: 10;
  display: flex;
  flex-direction: column;

}


.float-options {
  position: fixed;
  bottom: 15vh;
  right: 4.5vh;
  display: flex;
  flex-direction: column;
  gap: 10px;
  opacity: 0;
  transform: scale(0);
  transform-origin: top right;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.float-options.open {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.float button {

  background: linear-gradient(180deg, #febf24 0%, #ea4232 100%) !important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  filter: drop-shadow(1px 1px 1px);
}

.sun-img {
  width: 60px;
  height: 60px;
}

.bounce {
  border-radius: 50% !important;
  background: white !important;
  width: 50px;
  height: 50px;
  font-size: x-large;
  transition: transform 0.3s ease, background-color 0.3s ease;
  color: white;
}

.rotate {
  transform: rotate(90deg);
  transition: transform 1s ease
}

.float-btn {
  position: fixed;
  bottom: 0;
}

.float-options button {
  animation: fadeIn 0.5s;
}

.float-options button {
  margin: 5px;
  background: none !important;
  border: none;
  color: #333;
  cursor: pointer;
  transition: transform 0.5s ease, color 0.3s ease;
  transform: translateY(30px);
  animation: floatIn 1s forwards;
}

.float-options.open button {
  transform: translateY(0);
}

.scroll-btn {

  width: 45px;
  height: 45px;
  border-radius: 50% !important;
  color: #ffffff;
  background: linear-gradient(180deg, #febf24 0%, #ea4232 100%) !important;
  margin-right: 2vh;
  margin-top: 0.5vh;


  svg {
    width: 30px;
    height: 30px;

  }
}



@keyframes floatIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}