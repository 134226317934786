.Homepage {
  width: 100%;

  .landing-box-main {
    width: 100%;
    height: 85vh;
    // background: linear-gradient(to right, #fff7e0 0%, #f2d3c9 100%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../assests/Rectanglecurve.png");
    border-bottom-left-radius: 15px;
  }

  .landing-box {
    width: 100%;
    display: flex;
  }

  // .landing-box:before {
  //     content: '';
  //     position: absolute;
  //     bottom: 0;
  //     left: 0;
  //     border-radius: 20px;
  //     border-top: 80px solid white;
  //     border-left: 80px solid #F2D3C9;
  //     width: 0;
  //   }

  .landing-box-left {
    width: 45%;
    height: 70vh;
    margin: 30px 0 0 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .landing-box-right {
    width: 50%;
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;

    // img {
    //   width: 80%;
    //   height: 80%;
    // }

    .bannerhome {
      width: 613px;
      height: 476px;
      object-fit: contain;
    }
  }

  // .half_circle {
  //   border: 1px solid red;
  // }

  .landing-bar-main {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .landing-bar {
    width: 1300px;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin: auto;
    margin-top: -20px;
    background: white;
    border-radius: 50px;
    // filter: drop-shadow(1px 1px 1px);
    border: 1px solid #EE5C31;
    font-size: 16px;
    padding: 0 30px;
  }

  .landing-bar-mobile {
    display: none;
  }

  .head-main {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #5B8E3E;
  }

  .img-homepage {
    width: 150px;
    height: 50px;
    object-fit: contain;
  }

  .start-btn {
    border-radius: 5px;
    height: 40px;
    background: linear-gradient(to right, #ffcc21 0%, #ea4335 100%);
    font-weight: 400;
    font-size: 14px;
    border: none;
    padding: 5px 20px 5px 20px;
    margin: 0 20px 0 0;
    color: white;
    cursor: pointer;
  }

  .start-btn2 {
    border: none;
    background: none;
    color: white;
  }

  .text-box {
    font-family: "Poppins";
    width: 75%;
    font-size: 2.5em;
    // text-align: left;
    color: #133B58;
    margin-left: 50px;

    p {
      font-family: "Poppins";
      margin-bottom: 75px;
      font-size: 16px;
      font-weight: 400;
      color: #4a5568;
      margin-top: 20px;
      line-height: 30px;
    }
  }

  .soft {
    font-family: "Poppins";
    color: #EA4232;
    font-weight: 300;
    font-size: 45px;
  }

  .team {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 45px;
    background: linear-gradient(to left, #ffcc21 0%, #ea4335 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }

  .built {
    font-family: "Poppins";
    font-weight: 800;
    font-size: 45px;
    color: #133B58;
  }

  .btn-box {
    display: flex;
    justify-content: left;
    margin-left: 50px;
    width: 40%;
    text-align: left;
  }

  .review-box-main {
    display: flex;
    gap: 97px;
    justify-content: space-around;
    padding: 94px 180px;
    margin-top: 94px;
  }

  .review-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 16px;
    text-align: center;

    .icon-div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 65px;
      height: 65px;
      border-radius: 50%;
      border: 1px solid #ef5f32;
      background: #fff9f3;
      margin-bottom: 15px;

      svg {
        width: 35px;
        height: 35px;
      }
    }

    .safe {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0.03em;
      color: #5B8E3E;
      margin-bottom: 15px;
    }

    span {
      font-weight: 600;
    }

    .para {
      font-family: "Poppins";
      font-size: 14px;
      font-weight: 400;
      line-height: 27px;
      color: #131115;
    }
  }


}

@media only screen and (max-width: 480px) and (min-width: 390px) {
  .Homepage {

    .landing-box-main {
      height: 100%;
    }

    .landing-box {
      flex-direction: column;
    }

    .landing-box-lext {
      order: 0;
      width: 100%;
      height: 100%;
      align-items: start;
    }

    .landing-box-right {
      order: -1;
      width: 100%;
      height: 100%;
      padding-top: 69px;
      padding-bottom: 39px;

      .bannerhome {
        width: 350px;
        height: 281px;
      }
    }

    .text-box {
      width: 100%;
      width: 279px;
      padding-left: 20px;
      font-family: "Inter";
      font-size: 24px;
      font-weight: 800;
      line-height: 30px;
      text-align: left;

      p {
        margin-top: 13px;
        margin-bottom: 39px;
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
      }
    }

    .soft {
      font-family: "Inter";
      font-size: 24px;
      font-weight: 800;
      line-height: 30px;
      text-align: left;
    }

    .built {
      font-family: "Inter";
      font-size: 24px;
      font-weight: 800;
      line-height: 30px;
      text-align: left;
    }

    .team {
      font-family: "Inter";
      font-size: 24px;
      font-weight: 800;
      line-height: 30px;
      text-align: left;
    }

    .landing-bar {
      display: none;
    }

    .landing-bar-mobile {
      position: relative;
      top: 40px;
      width: 352px;
      height: 51px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: auto;
      background: white;
      border-radius: 35px;
      border: 1px solid #EE5C31;
      font-size: 16px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .head-main {
      font-family: "Poppins";
      font-size: 12px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      color: rgba(19, 59, 88, 1);
    }

    .img-homepage {
      width: 77px;
      height: 26px;
    }

    .review-box-main {
      margin: 0;
      // padding: 48px;
      flex-direction: column;
      // margin-top: 48px;
      gap: 35px;
    }

  }
}