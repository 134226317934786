.company-policy {
  padding: 40px 100px 51px 100px;

  .icon-file {
    width: 64px;
    height: 64px;
    border-radius: 11px;
    padding: 15px;
    background: linear-gradient(180deg, #febf24 0%, #ea4232 100%);
  }

  .main-data {
    margin-bottom: 75px;
    display: flex;
    gap: 50px;
  }

  .data {
    display: flex;
    gap: 17px;
  }

  .head-text {
    font-family: "Poppins";
    font-size: 40px;
    font-weight: 600;
    line-height: 40.6px;
    margin-bottom: 15px;
    color: #000000;
  }

  .file-2 {
    width: 529px;
  }

  .txt {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #000000;
  }

  li {
    margin-left: 17px;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #000000;
  }

  .para {
    text-align: justify;

    h1 {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    h2 {
      font-size: 16px;
      font-weight: 400;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}

@media only screen and (max-width: 480px) and (min-width: 390px) {
  .company-policy {
    padding: 41px 21px;

    .para {
      font-family: "Poppins";
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      text-align: left;
      h1 {
        font-family: "Poppins";
        font-size: 22px;
        font-weight: 600;
        line-height: 22.33px;
        text-align: left;
      }
    }
  }
}
