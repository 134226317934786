.aboutus-values {
  padding: 0px 162px 248px 162px;

  .heading-color {
    font-size: 72px !important;
    font-weight: 500 !important;
    line-height: 102.82px !important;
    text-align: center !important;
    margin-bottom: 81px;
  }

  .main-card {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
  }

  .box {
    width: 267px;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    // padding: 42px 93.5px 42.5px 93.5px;
    border-radius: 30px;
    border: 1px solid #febf24;
  }

  .box-icon {
    text-align: center;
    margin-bottom: 59.58px;
  }

  .txt{
    font-family: "Poppins";
        font-size: 24px;
        font-weight: 700;
        line-height: 25.7px;
        text-align: center;
        color: #5B8E3E;
  }
}

@media only screen and (max-width: 480px) and (min-width: 390px) {
  .aboutus-values{
    padding: 0 33px 33px 33px ;

    .heading-color{
        font-size: 22px !important;
        font-weight: 500 !important;
        line-height: 23.56px !important;
        margin-bottom: 39px;
    }

    .box{
      width: 148px;
        height: 131px;
        border-radius: 20px;
    }

    .box-icon{
      margin-bottom: 25px;

      svg{
        width: 50px;
        height: 50px;
      }
    }

    .txt{
        font-size: 12px;
        font-weight: 700;
        line-height: 12.85px;
        text-align: center;
    }
  }
}
