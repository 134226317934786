.service-data {
  padding: 35px 71px 100px 71px;

  .txt {
    font-family: "Poppins";
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
    color: #ea4232;
  }

  .txt-1 {
    font-family: "Poppins";
    font-size: 40px;
    font-weight: 500;
    line-height: 58.2px;
    text-align: left;
    color: #5B8E3E;
    width: 925px;
    padding-bottom: 22px;
    border-bottom: 1px solid #3e3e3e;
  }

  .all-card-main {
    margin-top: 76px;
    border: 1px solid #febf24;
    border-radius: 36px;
    padding: 67px 0px 56px 0px;
    background: #fff9f3;
  }

  .card-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
  }

  .card {
    width: 352px;
    // height: 475px;
    padding: 41px 32px 41px 39px;
    border-radius: 30px;
    border: 2px solid #febf24;
    display: flex;
    flex-direction: column;
  }

  .icon {
    width: 153px;
    height: 153px;
    padding: 34px;
    border: 1px solid #febf24;
    border-radius: 50%;
    margin-bottom: 26px;
  }

  .title {
    font-family: "Poppins";
    font-size: 25px;
    font-weight: 600;
    line-height: 27.63px;
    text-align: left;
    margin-bottom: 14px;
    color: #5B8E3E;
  }

  .des {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 400;
    line-height: 28.17px;
    text-align: left;
    margin-bottom: 26px;
  }

  .noramlSelected {
    display: flex;
    align-items: center;
    border: none;
    border: 1px solid #febf24;
    border-radius: 7px;
    width: 266px;
    height: 62px;
    padding: 17px 70px;
    background: transparent;
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: center;
    color: #525255;
  }

  .noramlSelected:hover {
    background: #ea4232;
    color: white;
  }

  .selected {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 7px;
    width: 266px;
    height: 62px;
    padding: 17px 70px;
    background: #ea4232;
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: center;
    color: #ffffff;
  }

  .selected-icon {
    margin-left: 4px;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .noramlSelected-icon {
    margin-left: 10px;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: linear-gradient(270deg, #febf24 0%, #ea4232 100%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .inn-sel {
    width: 17px;
    color: rgba(234, 66, 50, 1);
  }

  .inn-non {
    width: 17px;
    color: white;
  }

  .choose {
    margin-top: 128px;
    display: flex;
    // justify-content: space-between;
    align-items: center;
  }

  .choosetxt {
    font-family: "Poppins";
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
    color: #ea4232;
    margin-bottom: 29px;
  }

  .choose-main-txt {
    font-family: "Poppins";
    font-size: 40px;
    font-weight: 500;
    line-height: 58.2px;
    text-align: left;
    color: #5B8E3E;
    width: 639px;
    margin-bottom: 46px;
  }

  .inner-data {
    width: 639px;
    display: flex;
    gap: 22px;
    margin-bottom: 22px;
  }

  .chose-icon {
    border: 1px solid #febf24;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 10px;
  }

  .chose-head {
    font-family: "Poppins";
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
    color: #5B8E3E;
  }

  .chose-nrml-head {
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: #1d2434;
  }

  .chooseimg {
    width: 100%;
    // height: 379px;
    object-fit: cover;
  }

  .in-touch {
    margin-top: 129px;
  }

  .get-in-touch {
    display: flex;
    flex-direction: column;
    padding: 90.45px 60.5px;
    width: 1200px;
    height: 400px;
    border-radius: 30px;
    // background: linear-gradient(303.97deg, rgba(254, 191, 36, 0.4) 11.67%, rgba(234, 66, 50, 0.4) 49.87%);
    background-image: url("../../assests/rocketall.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .rocket {
    object-fit: contain;
    width: 465px;
    height: 334.19px;
  }

  .touch-txt {
    width: 588px;
    font-family: "Poppins";
    font-size: 45px;
    font-weight: 800;
    line-height: 49.73px;
    text-align: left;
    color: #5B8E3E;
    margin-bottom: 56px;
  }

  .touch-btn {
    width: 173px;
    height: 63px;
    // padding: 17px 35px 17px 35px;
    border-radius: 7px;
    background: #ea4232;
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: center;
    color: #ffffff;
    border: none;
  }

  .started-btn {
    width: 173px;
    height: 63px;
    // padding: 17px 35px 17px 35px;
    border-radius: 7px;
    background: white;
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: center;
    color: #292930;
    border: none;
    border: 1px solid #febf24;
  }

  .inner-icc {
    display: none;
  }
}

@media only screen and (max-width: 480px) and (min-width: 390px) {
  .service-data {
    padding: 20px;

    .card {
      padding: 11px;
      width: 100%;
    }

    .txt-1 {
      font-size: 14px;
      font-weight: 500;
      line-height: 20.37px;
      text-align: left;
      width: 100%;
    }

    .all-card-main {
      margin-top: 73px;
      padding: 20px;
    }

    .icon {
      display: none;
      width: 58px;
      height: 58px;
      border-radius: 50%;
      padding: 11px;

      svg {
        width: 36px;
        height: 36px;
      }
    }

    .title {
      display: none;
    }

    .inner-icc {
      display: block;
    }

    .inner-inn {
      display: flex;
      gap: 14px;
    }

    .icons {
      width: 58px;
      height: 58px;
      border-radius: 50%;
      padding: 11px;
      border: 1px solid #febf24;
      border-radius: 50%;
      margin-bottom: 26px;

      svg {
        width: 36px;
        height: 36px;
      }
    }

    .titles {
      margin-top: 10px;
      font-family: "Poppins";
      font-size: 16px;
      font-weight: 600;
      line-height: 27.63px;
      text-align: left;
      color: rgba(234, 66, 50, 1);
    }

    .des {
      font-size: 12px;
      font-weight: 400;
      line-height: 19.44px;
      text-align: left;
      margin-bottom: 20px;
      color: #1d2434;
    }

    .noramlSelected {
      width: fit-content;
      height: 40px;
      margin: auto;
    }

        .selected{
      height: 40px;
      width: fit-content;
      margin: auto;
        }

    .choose {
      flex-direction: column;
      margin-top: 100px;
    }

    .choose-1 {
      order: 0;
      margin-top: 35px;
    }

    .choosetxt {
      font-size: 22px;
      font-weight: 600;
      line-height: 33px;
      text-align: left;
      margin-bottom: 14px;
    }

    .choose-main-txt {
      width: 100%;
      font-size: 14px;
      font-weight: 500;
      line-height: 20.37px;
      text-align: left;
    }

    .inner-data {
      width: 100%;
    }

    .chose-head {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      text-align: left;
    }

    .chose-nrml-head {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
    }

    .choose-2 {
      order: -1;

      img {
        width: 334px;
      }
    }

    .in-touch{
      margin-top: 50px;
    }

    .get-in-touch{
      display: none;
      width: 350px;
      padding: 0;
      background-size: contain;
    }

    .touch-txt{
      width: 100%;
        font-size: 12px;
        font-weight: 600;
        line-height: 13.26px;
        text-align: left;
    }
  }
}
