.HealthCareLanding {
  padding: 77px 98px 32px 78px;
  background: #ffffff;

  .hire-health {
    padding: 36px 12px;
    width: 749px;
  }

  .HealthCareLanding-main{
    display: flex;
    justify-content: space-between;
  }

  .small-text {
    margin-bottom: 24px;
    width: 580px;
    font-family: "Poppins";
    font-size: 17.34px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #1d2434b8;
  }

  .small-higlight {
    font-family: "Poppins";
    font-size: 17.03px;
    font-weight: 600;
    line-height: 26px;
    text-align: left;
    color: #1d2434;
  }

  .hireNowContainer {
    display: flex;
    align-items: center;
    width: 468px;
    padding: 5px 6px;
    border: 2px solid #ccc;
    border-radius: 50px;
    overflow: hidden;
    /* Ensures no inner elements spill out */
  }

  .emailInput {
    height: 40px;
    padding: 0 10px;
    flex-grow: 1;
    border: none;
    font-weight: 400;
    outline: none;
    /* Removes the focus outline to merge with the button */
    font-size: 16px;
  }

  .hireButton {
    background-color: #ea4232;
    color: white;
    font-weight: 500;
    font-size: 16.59px;
    padding: 14px 20px;
    border-radius: 50px;
    /* Adjusted padding to vertically center text */
    border: none;
    cursor: pointer;
  }

  .form-page-health {
    width: 459px;
    max-width: 459px;
    height: 547px;
    border-radius: 28px;
    padding: 38.55px 31px 35px 31px;
    background-color: #FFF9F3;
    box-shadow: 0px 4px 4px 0px #00000040;

  }

  .register {
    font-family: "Inter";
    font-size: 24px;
    font-weight: 700;
    line-height: 29.05px;
    text-align: center;
    color: #ea4232;
    margin-bottom: 34.27px;
  }

  .input-space {
    margin-bottom: 20px;
  }



  .input-space:last-child {
    margin: 0px;
  }

  .form-control {
    font-family: "Inter";
    border: 0.5px solid #ea4232;
    // border-image-slice: 1;
    // border-image-source: linear-gradient(180deg, #FEBF24 0%, #EA4232 100%);
    border-radius: 8px;
    height: 45px;
    padding: 11px 12px 11px 24px;
    font-size: 14px;
    color: #252525 !important;
    background: #FFF9F3;
  }

  ::placeholder {
    font-size: 14px;
    color: #252525 !important;
  }

  .form-control:focus {
    background-color: #fff;
    border-color: #ea4232;
    outline: 0;
    box-shadow: none;
  }

  .card {
    border: none;
  }

  .p-dropdown {
    border: 0.5px solid #ea4232;
    border-radius: 8px;
    height: 45px;
    padding: 11px 12px 11px 24px;
    background: #FFF9F3;
  }

  .p-inputtext {
    font-family: "Inter";
    font-size: 14px;
    color: #252525 !important;
    background: #FFF9F3;
  }

  .health-submit{
    font-family: "Inter";
      font-size: 18px;
      font-weight: 600;
      line-height: 21.78px;
    display: flex;
    margin: auto;
    margin-top: 65px;
    padding: 11px 48px;
    background: linear-gradient(90deg, #FEBF24 0%, #EA4232 100%);
    color: #FFF9F3;
    border: none;
    border-radius: 6px;
  }
}


@media only screen and (max-width: 480px) and (min-width: 340px) {
  .HealthCareLanding{
    padding: 30px !important;
    

    .HealthCareLanding-main{
      flex-direction: column;
    }
    
    .hire-health{
      max-width: 100% !important;
      width: 100%;
      padding: 5px;
    }

    .heading-main{
      width: 100%;
      font-size: 22px !important;
      line-height: 30px !important;
    }

    .heading-color{
      font-size: 22px !important;
    }

    .small-text{
      width: 100% !important;
    }

    .hireNowContainer{
      width: 100%;
    }

    .form-page-health{
      max-width: 100% !important;
      width: 100%;
    }

    .hireButton{
      padding: 5px 8px;
    }
 
  } 
}